<template>
  <v-container id="bankEmployees" tag="section">
     <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="`headline green white--text">
     
          Dados do colaborador
           <v-spacer />
      
          <v-btn text @click="clearFields()"><v-icon large color="white">mdi-close</v-icon></v-btn>
      
        </v-card-title>
        <v-divider></v-divider>
        <v-text-field v-model="name" class="ma-5" label="Nome" outlined readonly></v-text-field>
        <v-text-field v-model="email" class="ma-5" label="E-mail" outlined readonly></v-text-field>
        <v-text-field v-model="phone" class="ma-5" label="Telefone" outlined readonly></v-text-field>
        <v-text-field v-model="cpf" class="ma-5" label="CPF" outlined readonly></v-text-field>
        <v-text-field v-model="dateBirth" class="ma-5" label="Data de nascimento" outlined readonly></v-text-field>
        <v-text-field v-model="funcional" class="ma-5" label="Funcional" outlined readonly></v-text-field>
        <!-- <v-text-field v-model="message" class="ma-5" label="Mensagem" outlined readonly></v-text-field> -->

        <v-divider></v-divider>

      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Main
    ============================================================-->
 
 <v-btn color="orange" class="ma-2 white--text" >
    <export-excel
    class   = "btn btn-default"
    :data   = "json_data"
    :fields = "json_fields"
    worksheet = "Funcionarios"
    name    = "Funcionarios.xls">
  
      <v-icon left dark>mdi-download</v-icon>Exportar Dados
  
</export-excel>
  </v-btn>
    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
    />
  </v-container>
</template>

<script>
import bankEmployeesService from "../../../../service/bankEmployeesService";

export default {
  name: "bankEmployees",
  components: {
    Tables: () => import("../../../../components/cms/Tables")
  },
  data: () => ({
    title: "Contatos Cadastrados",
    columns: ["Nome", "E-mail", "Telefone","Banco", "Ações"],
    actionsOptions: ["preview"],
    linesData: [],
    bankEmployees: [],
    cpf:"",
    funcional:"",
    openDialog: false,
    overlay:false,
    name: "",
    dateBirth:"",
    email: "",
    phone: "",
    json_fields: {},
    json_data: [],
    json_meta: [
        [
            {
                'key': 'charset',
                'value': 'utf-8'
            }
        ]
    ]

  }),
  methods: {
    action($event) {
      switch ($event.type) {
        case "preview":
          this.preview($event.id);
          break;
        default:
          break;
      }
    },
    formatPhone(phone){
      const data = String(phone)
      return (`(${data.substring(0,1)}${data.substring(2,1)}) ${data.substring(2)}`)
    },
    formatDate(date){
      const data = String(date)
      return `${data.substring(0,2)}/${data.substring(2,4)}/${data.substring(4)}`
    },
     formatCpf(date){
      const data = String(date)
      return (`${data.substring(0,3)}.${data.substring(3,3)}.${data.substring(6,3)}-${data.substring(7)}`)
    },
    getAll() {
      this.overlay = true;
      bankEmployeesService.get({}).then(res => {
        this.overlay = false;
        this.bankEmployees = res.data;
        this.json_fields = {
          'Nome': 'name',
          'Email': 'email',
          'Telefone': 'phone',
          'CPF': 'cpf',
          'Banco': 'bank',
          'Data de Nascimento': 'date_birth',
          'Funcional': 'funcional'
        }
        this.linesData = res.data.map(bankEmployees => {
          bankEmployees.phone = this.formatPhone(bankEmployees.phone)
          bankEmployees.date_birth = this.formatDate(bankEmployees.date_birth)
          this.json_data.push(bankEmployees)
          return {
            id: bankEmployees.id,
            email: bankEmployees.email,
            lines: [
              { width: 25, name: bankEmployees.name },
              { width: 25, name: bankEmployees.email },
              { width: 20, name: bankEmployees.phone },
              { width: 20, name: bankEmployees.bank }
            ]
          };
        });
        this.overlay=false
      });
    },
    preview(id) {
      const data = this.bankEmployees.filter(res => res.id === id)[0];
      this.name = data.name;
      this.email = data.email;
      this.cpf = data.cpf;
      this.phone = data.phone
      this.bank = data.bank;
      this.funcional=data.funcional;
      this.dateBirth=data.date_birth;
      this.openDialog = true;
    },
    clearFields() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.openDialog = false;
    }
  },
  mounted() {
    this.getAll();
  }
};
</script>
